import { Injectable } from '@angular/core';
// python api
export const Url = 'https://api.transfans.com/v1/python/';
// python upgrade api Url
export const UrlLink = 'https://api.transfans.com/v1/python/';

export const UrlTag = 'https://api.transfans.com/v1/admin/';
//  node api
export const devUrl = 'https://api.transfans.com/v1/';

// wallet url
export const walletUrl = 'https://api.transfans.com/v1/';

// new python api for categories
export const Url2 = 'https://api.transfans.com/v1/';

// analytics url
export const AnalyticsUrl = 'https://analytics.transfans.com/dashboard/#/';

//Making it Dynamic using /clodinary API
export const CLOUDINARY_BASE_URL ="https://res.cloudinary.com/"
export const CLOUDINARY_IMAGE = "/image/upload/"
export const CLOUDINARY_VIDEO ="/video/upload/"
export const CLOUDINARY_API_BASE = "https://api.cloudinary.com/v1_1/"

// icons
export const DEFAULT_PROFILE = "../../assets/images/defaultPerson.png"

@Injectable({
  providedIn: 'root',
})
export class GlobalConfig {
    DEFAULT_PROFILE = "../../assets/images/defaultPerson.png";
    CREATOR_ICON = "../../assets/icons/creator_icon.svg";

    PROFILE_WIDTH = 'c_scale,h_100,r_0,w_100/';

    /**
     * @description use this method in html number only input
     * @param event html input event
     */
    numberOnly(event): boolean {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }

    /**
     * @description method to get splitted text...
     * @param text your text (string)
     * @param count max characters (integet)
     */
    getSplittedChars(text, count): any{
      if(text.length <= (count || 50)) {return text};
      return text.slice(0,count || 50) + '...'
    }
}
