import {Action, combineReducers} from '@ngrx/store';
import * as types from '../actionTypes';


const initialState = {
    postViewLogs: false,
    postBreadcrumb: null,
    cloudinaryCreds: null,
    awsCognitoCreds: null,
    folderName: null
}

export function PostsReducer( state = initialState, action:any){
    switch(action.type) {
        case types.POST_VIEW_LOGS:
            return {
                ...state,
                postViewLogs: action.payload
            };
        case types.POST_BREADCRUMB:
            return {
                ...state,
                postBreadcrumb: action.payload
            }
        case types.SET_CLOUDINARY_CREDS:
        return {
            ...state,
            cloudinaryCreds: action.creds
        }
        case types.SET_AWS_COGNITO_CREDS:
            return {
                ...state,
                awsCognitoCreds: action.creds
            }
        case types.FOLDER_NAME:
          return {
              ...state,
              folderName: action.creds
          }
        default:
            return state;
    }
}
