import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { devUrl } from 'src/app/global/global';
import { Configuration } from './global/global-config';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpClient, private conf:Configuration) { }
  /**
   * @description getting cloudinary credentials
   * @author Anjali Patil
   * @date 09-08-2021
   * @returns cloudinary creds
   */

  getCludinaryCredsApi():Observable<any>{
    let headers = new HttpHeaders();
    const token: string = this.conf.getItem('token');
    headers = headers.append('authorization', token);
    return this.http.get(`${devUrl}cloudinary`,{ headers })
  }

  getAwsCognitoAppConfigApi():Observable<any>{
    let headers = new HttpHeaders();
    const token: string = this.conf.getItem('token');
    headers = headers.append('authorization', token);
    return this.http.get(`${devUrl}appConfig`,{ headers })
  }

  uploadVideoAwsCognito(): Observable<any> {
    const headers = new HttpHeaders()
    .set('authorization', localStorage.getItem('token'))
    .set('lan', localStorage.getItem('lan'))
    return this.http.get(`${devUrl}cognitoToken`, { headers })
  }
}
