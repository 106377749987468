import {Action} from '@ngrx/store';
import * as actionTypes from '../actionTypes';
export class PostsAction implements Action {
    type:string;
    payload: any;
}

export class PostsBreadcrumbAction implements Action {
    type:string;
    payload: any;
}

export const setCloudinaryCred = (creds)=>{
    return {
        type: actionTypes.SET_CLOUDINARY_CREDS,
        creds
    }
}

export const setAwsCognitoCred = (creds)=>{
    return {
        type: actionTypes.SET_AWS_COGNITO_CREDS,
        creds
    }
}

export const folderName = (creds)=>{
  return {
      type: actionTypes.FOLDER_NAME,
      creds
  }
}
